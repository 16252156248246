<template>
  <div class="searchJob">
    <Head
      ref="headSearch"
      :hId="1"
      :recruit="false"
      @keyup.enter.native="reload()"
    ></Head>
    <div class="main">
      <div class="content">
        <div class="classify">
          <div
            class="list"
            v-for="(item, indexs) in classifyList"
            :key="indexs"
          >
            <p class="title">{{ item.title }}</p>
            <!-- <el-radio-group
              v-model="jobSearch[item.mod]"
              @change="getIndexSearch(item.mod)"
              class="radioGroup"
            > -->
            <el-radio-group v-model="searchTime" class="radioGroup">
              <!-- <el-radio :label="''" @change="hideJobCode(indexs)"
                >不限</el-radio
              > -->
              <!-- <el-radio
                :label="item2.dictCode"
                v-for="(item2, index) in item.list"
                :key="index"
                @change="filterIobCode(indexs,jobSearch[item.mod])"
                >{{ item2.dictValue }}</el-radio
              > -->
              <el-radio
                @change="getIndexSearch(item.mod, item2.dictCode)"
                :label="item2.dictCode + 'pd' + item2.dictValue"
                v-for="(item2, index) in item.list"
                :key="index"
                >{{ item2.dictValue }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="positionSearch recommendJob">
          <div class="title2 fcc" id="search">
            <img src="@/assets/img/Index_JobSeeker/title3.svg" alt="" />
          </div>
          <div class="job">
            <Position
              :connect="'true'"
              v-for="(item, index) in jobList"
              :key="index"
              @click.native="goToDetails(item.id)"
              :val="item"
            ></Position>
          </div>
          <el-empty v-if="!total" :image-size="100"></el-empty>
          <el-footer class="listPage" v-if="total > 0">
            <el-pagination
              :total="total"
              :current-page="jobSearch.pageNum"
              :page-size="jobSearch.pageSize"
              layout="total, prev, pager, next, jumper"
              @size-change="
                (size) => {
                  jobSearch.pageSize = size;
                  getIndexSearch();
                }
              "
              @current-change="
                (num) => {
                  jobSearch.pageNum = num;
                  getIndexSearch();
                }
              "
            ></el-pagination>
          </el-footer>
        </div>
        <div class="recommendJob">
          <div class="title2 fcc">
            <img src="@/assets/img/Index_JobSeeker/title2.svg" alt="" />
          </div>
          <div class="job">
            <Position
              :connect="'true'"
              v-for="(item, index) in recommendPostList"
              :key="index"
              @click.native="goToDetails(item.id)"
              :val="item"
            ></Position>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Position from "@/components/Position.vue";
import {
  indexSearch,
  classificationSearch,
  recommendPost,
} from "../../api/index";
import { jobListPage } from "@/api/myResume";
export default {
  name: "searchJob",
  components: {
    Head,
    Footer,
    Position,
  },
  inject: ["reload"],
  data() {
    return {
      classifyList: [],
      jobList: [],
      jobSearch: {
        pageNum: 1,
        pageSize: 15,
        enterpriseName: null,
        jobType: null, //类型
        recruitJob: null, //行业
        jobCode: null, //职位
        enterpriseNature: null, //公司性质
        wages: null, //期望月薪
        education: null, //学历要求
        companyExperience: null, //工作经验
        enterprisePerson: null, //公司规模
      },
      searchTime: null, //临时筛选
      total: null,
      recommendPostList: [],
    };
  },
  created() {
    // this.jobSearch.enterpriseName = this.$route.query.value
    //   ? this.$route.query.value
    //   : null;
    this.searchTime = this.$route.query.value ? this.$route.query.value : null;
    this.getSearchList();
    this.getIndexSearch("enterpriseName",this.searchTime);
    this.getRecommend();
  },
  mounted() {},
  methods: {
    //  跳转详情页
    goToDetails(id) {
      this.$router.push({ path: "/indexDetails", query: { id: id } });
    },
    // 搜索
    getIndexSearch(type, val) {
      let searchObj = {
        pageNum: this.jobSearch.pageNum,
        pageSize: this.jobSearch.pageSize,
      };
      if (type != "enterpriseName") {
        this.jobSearch.enterpriseName = null;
        this.$refs.headSearch.searchVal = null;
      }
      searchObj[type] = val;
      console.log(type);
      indexSearch(searchObj).then((res) => {
        this.jobList = res.rows;
        this.total = res.total;
        // console.log(type)
        // setTimeout(() => {
        //   !type && (document.documentElement.scrollTop = 436)
        // })
        this.$router.push({
          path: "/searchJob",
          query: { value: this.jobSearch.enterpriseName },
        });
      });
    },
    // 分类条件
    getSearchList() {
      classificationSearch({}).then((res) => {
        this.classifyList = [
          {
            title: "类型",
            list: res.data.jobType,
            mod: "jobType",
          },
          {
            title: "行业",
            list: res.data.position,
            mod: "recruitJob",
          },
          {
            title: "公司性质",
            list: res.data.recruitJob,
            mod: "enterpriseNature",
          },
          {
            title: "期望月薪",
            list: res.data.wages,
            mod: "wages",
          },
          {
            title: "学历要求",
            list: res.data.education,
            mod: "education",
          },
          {
            title: "工作经验",
            list: res.data.companyExperience,
            mod: "companyExperience",
          },
          {
            title: "公司规模",
            list: res.data.enterprisePerson,
            mod: "enterprisePerson",
          },
        ];
      });
    },

    // 获取推荐职位
    getRecommend() {
      recommendPost({ pageNum: 1, pageSize: 21 }).then((res) => {
        this.recommendPostList = res.rows;
      });
    },

    // 筛选职位
    filterIobCode(index, id) {
      if (index == 1) {
        let arr = {
          title: "职位",
          list: [],
          mod: "jobCode",
        };
        jobListPage({ parentId: id }).then((res) => {
          arr.list = res.data;
        });
        this.classifyList.splice(2, 0, arr);
        this.classifyList = this.unique(this.classifyList);
      }
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.mod) && res.set(arr.mod, 1));
    },

    // 隐藏职位
    hideJobCode(index) {
      if (index == 1) {
        this.classifyList.splice(
          this.classifyList.findIndex((item) => item.mod === "jobCode"),
          1
        );
        this.jobSearch.jobCode = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.searchJob {
  width: 100%;
  height: 100%;
  .main {
    min-height: calc(100% - 278px);
  }
  .classify {
    background-color: #ffffff;
    padding: 10px 17px;
    margin-top: 20px;
    .list {
      display: flex;
      min-height: 52px;
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: none;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        width: 56px;
        min-width: 56px;
        margin-right: 40px;
        height: 52px;
        line-height: 52px;
      }
    }
    /deep/ .radioGroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .el-radio {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-right: 24px;
        height: 52px;
        line-height: 52px;
        cursor: pointer;
        &:hover {
          color: #0780e6;
        }
        &:last-child {
          margin-right: 0;
        }
        .el-radio__input {
          display: none;
        }
        .el-radio__label {
          padding-left: 0;
        }
      }
    }
  }
  .recommendJob {
    margin: 20px 0;
    .title2 {
      height: 98px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 45%,
        #ffffff 100%
      );
      margin-bottom: 20px;
    }
    .job {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::after {
        display: block;
        content: "";
        width: 386px;
      }
    }
  }
}
</style>
